import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import HeaderWeb from '../shared/Header';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getKycDetails, kycDetailsAsync } from '../../reducers/kycDetails.slice';
import { getBeneficiaries } from '../../reducers/beneficiaries.slice';
import { kycDetailsWithMAIDsReqPayload } from '../../interfaces/familyMemberDetails.interface';
import { getBaseProfile } from '../../reducers/baseProfile.slice';

interface HalComponent extends HTMLElement {
  details: {
    policyId: number;
    maid: number;
    dbType: string;
  };
  loginDetails: {
    name: string;
    username: string;
    password: string;
  };
}

const HALWebComponents = () => {
      const navigate = useNavigate();
      const halRef = useRef<HalComponent | null>(null);
      const benefResponse = useAppSelector(getBeneficiaries);
      const baseProfile = useAppSelector(getBaseProfile);
       
  useEffect(() => {
    const halElement = halRef.current;
    if (halElement) {
      const onComponentReady = () => {
        halElement.details = {
          policyId: benefResponse?.beneficiaryDetails[0]?.policyId,          
          maid: baseProfile?.data?.maid,
          dbType: 'MAUHS',
        };
        halElement.loginDetails = {
          name: 'test1234',
          username: 'test1234',
          password: 'EligibilitySearchStaging',
        };
      };

      if (customElements.get('hal-component')) {
        onComponentReady();
      } else {
        customElements.whenDefined('hal-component').then(onComponentReady);
      }
    }
  }, [benefResponse,baseProfile]);

  return (
    <section>
<div>
   <HeaderWeb
   isShowBackButton={true}
   oldHeader={true}
   name={"Survival Certificate"}
   onBackBtnClick={() => {   navigate(-1);}}
                            />
</div>
      <div style={{marginTop:"72px"}}>
        <div>
          <hal-component ref={halRef}></hal-component>
        </div>
      </div>
    </section>
  );
};

export default HALWebComponents;
