import {
    Footer
  } from '@mediassistrepo/maven-old';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector } from '../../app/hooks';
import { getActiveClaims } from '../../reducers/activeClaims.slice';
import { getClaimList, getFreshRequestClaimList } from '../../reducers/claimList.slice';
import { redirect } from "react-router-dom";
import { getConfig } from '../../reducers/config.slice';
import { getflexDetails } from '../../reducers/flexDetails.slice';
import { getEnrollment } from '../../reducers/enrollment.slice';
import { postToNativeApp } from '../../common/helpers';
import { getActiveBeneficiaries, getIsRakshaPrime } from '../../reducers/beneficiaries.slice';
import useWindowWidth from '../../hooks/getWindowWidth';
import { GaEvent } from '../../utils/GA_services';
import { Account_clk_F, Calim_clk, FooterPage, Home_clk, Policy_clk } from '../../common/common.constants';
import { dataLayerPush } from '../../utils/gtm';
import Constants from '../../constants/Constants';

const FooterWeb = (props:any) => {
    const navigate = useNavigate();
    const corporateConfig = useAppSelector(getConfig);
    const activeBenefs = useAppSelector(getActiveBeneficiaries);
    const flexDetails = useAppSelector(getflexDetails);
    const enrollmentDetails = useAppSelector(getEnrollment);
    const freshClaimListCount = useAppSelector(getFreshRequestClaimList);
    const location = useLocation();
    const isRakshaPrime = useAppSelector(getIsRakshaPrime);

    const windowWidth = useWindowWidth();

    const activeClaimsResponse = useAppSelector(getActiveClaims)

    return (
      <>
      {
      windowWidth < 1023 && 
        <Footer
        isRakshaPrime={isRakshaPrime}
        firstTimeLogin= {props?.firstTimeLogin}
        isWalkthroughTint={props?.isWalkthroughTint}
        footerActive={props?.footerActive}
        headerActive={props?.headerActive}
        dataLayerPush={dataLayerPush}
          showSubmitClaim = {props.showSubmitClaim}
          onSubmitClaimClick={() => {
            dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
              header: "Actions", 
              sub_header : "Submit claim"
            });
            navigate("/claimSubmission/IPD")}}
          onHomeClick={() =>{ 
            GaEvent(Home_clk, {category: FooterPage });
            dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
              header: "Home", 
              sub_header : "NA"
            });
            navigate("/");
          }}
          onClaimsClick={() => { GaEvent(Calim_clk, {category: FooterPage });
          dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
            header: "Claims", 
            sub_header : "NA"
          }); navigate("/claim-list")}}
          onHospitalClick={() =>{GaEvent(Policy_clk, {category: FooterPage }); 
          dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
            header: "Policies", 
            sub_header : "NA"
          });
          navigate("/policy-list")}}
          onProfileClick={() => {
            GaEvent(Account_clk_F, {category: FooterPage });
            dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
              header: "Account", 
              sub_header : "NA"
            });
            if (location?.pathname?.includes('/account') && props?.isMultiUserActive) {
              navigate(0);
            } else if(!location?.pathname?.includes('/account')) {
              navigate('/account');
            }
          }}
          claims={freshClaimListCount || 0}
          onTrackClaim={() => {dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
            header: "Actions", 
            sub_header : "Track claim"
          });navigate("/claim-list")}}
          onSearchNetworkHospital={() => {dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
            header: "Actions", 
            sub_header : "Search network hospital"
          });navigate("/hospitals")}}
          onEcardDownload={() => {dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
            header: "Actions", 
            sub_header : "Download E-card"
          });
          props?.setSectionName?.("Bottom Navigation Bar");
          props.handleEcard()}}
          onRaiseQueryClick={() => {dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
            header: "Actions", 
            sub_header : "Need help?"
          });
          navigate("/help")}}
          onAddMemberClick={() => {navigate("/enrollment")}}
          onSubmitIntimation={() => {dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
            header: "Actions", 
            sub_header : "Submit intimation"
          });
          dataLayerPush(Constants.Instance.CLAIM_INTIMATION_INITIATE, { 
            cta_text : "Submit intimation", 
            section_name : "Bottom Navigation Bar", 
            header : "Actions"
          });
          navigate("/intimation")}}
          knowYourCoverage={() => {
            dataLayerPush(Constants.Instance.BOTTOM_NAVIGATION_INTERACTION, { 
              header: "Actions", 
              sub_header : "Know your coverage"
            });
            navigate("/intimation?oop=true")}}
          handleClickHospital={() => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Hospitalisation"
            });
            navigate("/claimSubmission/IPD"); navigate(0)}}
          handleClickClinic={() => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Clinic / OPD"
            });
            navigate("/claimSubmission/OPD"); navigate(0)}}
          handleClickWellness={() => { navigate("/claimSubmission/OPD?benefitsType=FLEXI-BENEFITS")}}
          corporateConfig={corporateConfig}
          flexDetails={flexDetails}
          enrollmentData={enrollmentDetails}
          onClose={props.onClose}
          hideEcardDownload={!activeBenefs?.length || corporateConfig?.hideEcard}
          
          claimSubmissionBenefits={props?.claimSubmissionBenefits}
          showEnhancBenefitModal={props?.showEnhancBenefitModal}
          closeEnhancBenefitModal={props?.closeEnhancBenefitModal}
          salesforceEntityId = {props?.salesforceEntityId}
          handleClickOPDCoverage={(props:any) => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "OPD Coverage"
            });
            navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
          }}
          handleClickSupportiveTherapy={(props:any) => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Supportive Therapy"
            });
           navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
          }}
          handleClickMentalHealth={(props:any) => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Mental Health"
            });
           navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
          }}
          handleClickMenopausePuberty={(props:any) => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Menopause & Puberty"
            });
           navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
          }}
          handleClickEnhancedBenefits={() => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Enhanced Benefits"
            });
            props?.setShowEnhancBenefitModal(true);
          }}
          handelClickEnhancedBenefitsModal={(props:any)=>{
            navigate(`/claimSubmission/OPD=${props}?benefitsType=FLEXI-BENEFITS`); navigate(0);
          }}
          handleClickAnnualHealthCheckup={(props:any) => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Employee's Annual Health Checkup"
            });
           navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
          }}
          handleClickOtherBenefits={(props:any) => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: props
            });
           navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
          }}
        />
      }
      </>

    )
}

export default FooterWeb;