import React, { useEffect, useState } from "react";
import { Header } from "@mediassistrepo/maven-old";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { baseProfileAsync, getBaseProfile } from "../../reducers/baseProfile.slice";
import { useLocation, useNavigate } from "react-router";
import { authenticateUser, getCookieValue, postToNativeApp } from "../../common/helpers";
import { getClaimList, getFreshRequestClaimList } from "../../reducers/claimList.slice";
import { getConfig } from "../../reducers/config.slice";
import useWindowWidth from "../../hooks/getWindowWidth";
import { getActiveClaims } from "../../reducers/activeClaims.slice";
import { getActiveBeneficiaries, getBeneficiaries, getIsRakshaPrime } from "../../reducers/beneficiaries.slice";
import { getflexDetails } from "../../reducers/flexDetails.slice";
import { dataLayerPush } from "../../utils/gtm";
import Constants from "../../constants/Constants";
import { getMultiUserData, getMultiUserDetails, multiUserDataAsync, multiUserDetailsAsync } from "../../reducers/multiUserDetails.slice";
import { multiUserLogin, multiUserLoginAccessToken } from "../../api/loginAPI";
import { saveAccessToken } from "../../reducers/login.slice";
import { getAccessToken } from "../../utils";
import Loader from "./Loader";
import { toast, ToastContainer } from "react-toastify";
import { ErrorIcon } from "../../assets/images/icon";
import { json } from "stream/consumers";
import { multiUserDataApi } from "../../api/accountAPI";

interface IBenefData {
  [key: string]: IBenef;
}

interface IBenef {
  open: boolean;
  encryptedToken: string;
  primeBenefName: string;
  empCode: string;
  polHolderName: string;
  isDefault: boolean;
}

const HeaderWeb = (props: any) => {
  const location = useLocation();
  const accessToken = getAccessToken();
  const multiUserDetails = useAppSelector(getMultiUserDetails);
  const nativeApp = getCookieValue('source') === 'native' ? true : false;
  const [showClaimSubmission,setShowClaimSubmission] = useState(props?.showSubmitClaim);
  const windowWidth = useWindowWidth();
  const {
    show = true,
    isShowBackButton = false,
    oldHeader = false,
    name = "",
    onBackBtnClick = null,
    handleEcard,
    policyDuration,
    policyNumber,
    linkPolicyHeader = false,
    isShowNotification = false,
    isNewNotification = false,
    isMultiUserWalkThrough = false,
    showProfileOptions = false
  } = props;

  const navigate = useNavigate();
  const baseProfile = useAppSelector(getBaseProfile);
  const activeBenefs = useAppSelector(getActiveBeneficiaries);
  const beneficiaryDetails = useAppSelector(getBeneficiaries);
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState([
    {
      id: 0,
      name: "",
      status: "",
      polHolderName:"",
    },
  ]);

  const corporateConfig = useAppSelector(getConfig);
  const activeClaimsResponse = useAppSelector(getActiveClaims);
  const freshClaimListCount = useAppSelector(getFreshRequestClaimList);
  const flexDetails = useAppSelector(getflexDetails);
  const [polHolderName, setPolHolderName] = useState<any>("");
  const isRakshaPrime = useAppSelector(getIsRakshaPrime);
  const [loader, setLoader] = useState<boolean>(false);
  const [ benefData, setBenefData ] = React.useState<IBenefData>({});
  const [activeNumber,setActiveNumber]=useState(0)
  const[activeNamePrevious,setActiveNamePrevious]=useState<number>();

useEffect(() => {
    if(!multiUserDetails && accessToken){
      dispatch(multiUserDetailsAsync());
    }
    if(!baseProfile?.data && accessToken){
      dispatch(baseProfileAsync());
    }
  }, [multiUserDetails]);

  useEffect(() => {
    const pribenef = beneficiaryDetails?.beneficiaryDetails.filter((x:any)=>x.relationToPrimaryId === 1);
     if(pribenef && pribenef.length > 0 && pribenef[0].polCorporateId){
      setPolHolderName(beneficiaryDetails?.policyDetails[0]?.polHolderName)
     };
    const data= baseProfile?.data?.userName;
    if(multiUserDetails?.users?.length>1 && data){
        const user = multiUserDetails?.users?.map((value :any, i:number) => ({
        id: i,
        name: value?.username,
        status: "",
        isActive: baseProfile?.data?.userName == value?.username ? true :false,
        polHolderName: value?.username === data ? beneficiaryDetails?.policyDetails[0]?.polHolderName : "",
        // policyDuration: value?.username === data? policyDuration : "",
        // policyNumber: value?.username === data? policyNumber : "",
        }));
      setOptions([...user]);
      postToNativeApp({options:user})
      return;
    }
    if (baseProfile?.data) {
      setOptions([
        {
          id: 0,
          name: baseProfile.data.firstName,
          status: "",
          polHolderName:beneficiaryDetails?.policyDetails[0]?.polHolderName
          // policyDuration:policyDuration,
          // policyNumber:policyNumber
        }
      ]);
      postToNativeApp({options:[
        {
          id: 0,
          name: baseProfile.data.firstName,
          status: "",
          isActive:true,
          polHolderName:beneficiaryDetails?.policyDetails[0]?.polHolderName
          // policyDuration:policyDuration,
          // policyNumber:policyNumber
        }
      ]})
    }
    
  }, [baseProfile,multiUserDetails,beneficiaryDetails]);

  useEffect(() => {
    
    if (getCookieValue('platform') == 'ios') {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    else if (getCookieValue('platform') == 'android') {

      document.addEventListener('message', handleMessage);
      return () => {
        document.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  useEffect(()=>{
    for(let i=0;i<options?.length;i++){
        if(options[i]?.name===baseProfile?.data?.firstName){
             setActiveNumber(i);
             setActiveNamePrevious(i)
             return;
              }
    }
    postToNativeApp({ "primaryBenef": baseProfile?.data?.firstName })
   },[options,baseProfile?.data?.firstName]);

  const handleMessage = async(event: any) => {
    const message = JSON.parse(event.data);
    if (message.event === 'switchProfile' ) {
      if(!multiUserDetails){
       const response = await multiUserDataApi(); 
       handleUserClick(message.data,response.data.encryptedToken);
      }
      else{
        handleUserClick(message.data);
      }

    }
  };

  useEffect(() => {
    if(windowWidth <= 1023){
      setShowClaimSubmission(false);
    }
  },[windowWidth]);
  
  const handleMultiUserRequest = async(request: any) => {   
    const response = await multiUserLogin(request); 

      return response.data

  

  }

  const handleMultipleUserProceed = async(req: any) => {
    const response:any = await multiUserLoginAccessToken(req);
    saveAccessToken(response?.data)
     return response?.data
  }

  
  const handleUserClick = async (user: string,encryptedToken:string = null) => {
    setLoader(true);
    if (benefData[user]) {
        const updatedData = {}
       
        setBenefData(updatedData);
        setLoader(false);
        return;
    }
    const req = { token:multiUserDetails?.encryptedToken ? multiUserDetails?.encryptedToken : encryptedToken, username: user };

    const requestTime = new Date().toISOString();
    const result = await handleMultiUserRequest?.(req); 
    if (result?.encryptedToken) {
     
        benefData[user] = { ...result, open: true };
        setBenefData(benefData);
        // GaEventForMoreDetails(LoginByContact_SelectedUser, LoginByContact, user, result);
        handleProceedClick(user)
        // APIRequest.instance.log(logData);
    }  else {
        notify();
        setActiveNumber(activeNamePrevious|| 0);
        //  setSelectedUsers([user]);
        // setError(result?.message || "Unable to get the data for this user.");
        setLoader(false);
    }
}


const handleProceedClick = async (user: string) => {
  setLoader(true)
  const { encryptedToken, isDefault } = benefData[user];
  const req = { token: encryptedToken, isDefault };
  const requestTime = new Date().toISOString();
  const result = await handleMultipleUserProceed?.({ token: encryptedToken, isDefault  })
  if (result?.accessToken) {
     
      postLogin(result);
  } else {
      notify();
      setActiveNumber(activeNamePrevious|| 0);
        setLoader(false);
    }
}


const postLogin =  (result:any) => {
  setLoader(true); 
    localStorage.clear();
    sessionStorage.clear();
    authenticateUser(result);
    if(localStorage.getItem('accessToken')){
      navigate("/")
      window.location.reload();
    }
    setLoader(false);
}

const notify = () => {

  toast.error( <div className="flex gap-4 items-start">
    <div>
      <ErrorIcon />
    </div>
    <div className="flex flex-col">
      <h3 className="text-sm font-semibold text-gray-900">
      Unable to switch account now
      </h3>
      <p className="text-xs font-normal text-gray-700 m-1">
      We ran into an issue while switching your account. Please try again later. If it keeps happening, contact us for help.      </p>
    </div>
  </div>, { toastId: "1" });
}

  return (
    <div className="sticky-div" style={{border:isRakshaPrime?"0":""}}>
         <ToastContainer
          autoClose={4000}
          position="bottom-right"
          hideProgressBar
        />
      {loader && <Loader></Loader>}
     { nativeApp && props.isHomeScreen? <div style={{ marginTop: 65  }}></div> :
  <Header
      claimSubmissionBenefits={props?.claimSubmissionBenefits}
      showEnhancBenefitModal={props?.showEnhancBenefitModal}
      closeEnhancBenefitModal={props?.closeEnhancBenefitModal}
      salesforceEntityId = {props?.salesforceEntityId}
      className={props?.className}
      isRakshaPrime={isRakshaPrime}
      rakshaPrimeClick={()=>navigate("/raksha-prime")}
      isMultiUser={props?.isMultiUser}
      isWalkthroughTint={props?.isWalkthroughTint}
      toggleHeader={props?.toggleHeader}
      headerActive={props?.headerActive}
      footerActive={props?.footerActive}
      dataLayerPush={dataLayerPush}
        showSubmitClaim={showClaimSubmission}
        openProfileOptions={showProfileOptions}
        linkPolicyHeader={linkPolicyHeader}
        isShowNotification={isShowNotification}
        isNewNotification={isNewNotification} 
        corporateConfig={corporateConfig}
        policyDuration={policyDuration}
        policyNumber={policyNumber}
        style={{ width: "100%", display: "flex" }}
        hiddenMb={!show}
        oldHeader={oldHeader}
        isShowBackBtn={isShowBackButton}
        name={name}
        subtitle={""}
        onBackBtnClick={() => {
          onBackBtnClick ? onBackBtnClick() : navigate(-1);
        }}
        options={options}
        onLogoutClick={() => {
          dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
            header: "Profile",
            sub_header:"Logout"
          });
          navigate("/logout");
        }}
        onLinkAccountClick={() => {
          dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
            header: "Profile",
            sub_header:"Link Account"
          });
          navigate("/link-policy")}}
        claimsCount={
          freshClaimListCount || 0
        }
        actions={{
          onNotification: () => {
            navigate("/notifications");
          },
          onSubmitClaimClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Submit Claim"
            });
            // navigate("/claimSubmission/IPD");
          },
          onTrackClaim: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Track Claim"
            });
            navigate("/claim-list");
          },
          onSearchNetworkHospital: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Search Network Hospital"
            });
            navigate("/hospitals");
          },
          onDownloadECard: () => {
            props?.setSectionName?.(Constants.Instance.TOP_NAVIGATION_BAR)
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Download E-card"
            });
            handleEcard();
          },
          onRaiseQueryClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Need help?"
            });
            navigate("/help");
          },
          onAddMemberClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Add Member"
            });
            navigate("/enrollment");
          },
          onSubmitIntimation: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Actions",
              sub_header:"Submit Intimation"
            });
            navigate("/intimation");
            dataLayerPush(Constants.Instance.CLAIM_INTIMATION_INITIATE, { 
              cta_text : "Submit intimation", 
              section_name : "Top Navigation Bar", 
              header : "Actions"
            });
            dataLayerPush(Constants.Instance.CLAIM_INTIMATION_INITIATE, { 
              cta_text : "Submit intimation", 
              section_name : "Top Navigation Bar", 
              header : "Actions"
            });
          },
          knowYourCoverage: () => {
            navigate("/intimation?oop=true");
          },

          handleClickClinic: () => {
            dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
              section_name: "Claim Submission",
              cta_text: "Clinic / OPD"
            });
            navigate("/claimSubmission/OPD");
            navigate(0);
          },
          handleClickWellness: () => {
            navigate("");
          },
        }}
        handleClickHospital={() => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Hospitalisation"
          });
          navigate("/claimSubmission/IPD");
          navigate(0);
        }}
        handleClickClinic={ () => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Clinic / OPD"
          });
          navigate("/claimSubmission/OPD");
          navigate(0);
        }}
        handleClickWellness={ () => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Wellness/OPD"
          });
          navigate("/claimSubmission/OPD?benefitsType=FLEXI-BENEFITS");
          navigate(0);
        }}
        navigations={{
          onHomeClick: () => {
            navigate("/");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Home",
              sub_header:"NA"
            });
          },
          onClaimsClick: () => {
            navigate("/claim-list");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Claims",
              sub_header:"NA"
            });
          },
          onPolicyClick: () => {
            navigate("/policy-list");
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Policy List",
              sub_header:"NA"
            });
          },
          onProfileClick: () => {
            dataLayerPush(Constants.Instance.TOP_NAVIGATION_INTERACTION, { 
              header: "Account",
              sub_header:"NA"
            });
            if (location?.pathname?.includes("/account")) {
              navigate(0);
            } else {
              navigate("/account");
            }
          },
        }}
        activeName={baseProfile?.data?.firstName}
        token={multiUserDetails?.encryptedToken}
        onClose={props.onClose}
        hideEcardDownload={!activeBenefs?.length || corporateConfig?.hideEcard}
        flexDetails={flexDetails}
        handleMultiUserRequest={handleMultiUserRequest}
        loginValue={baseProfile?.data?.mobileNo}
        handleLoginRedirect={() => {
          navigate("/")
          window.location.reload();
        }}
        handleMultipleUserProceed={handleMultipleUserProceed}
        isMultiUserWalkThrough={isMultiUserWalkThrough}
        multiUserData={multiUserDetails}

        handleClickOPDCoverage={(props:any) => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "OPD Coverage"
          });
          navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
        }}
        handleClickSupportiveTherapy={(props:any) => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Supportive Therapy"
          });
         navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
        }}
        handleClickMentalHealth={(props:any) => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Mental Health"
          });
         navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
        }}
        handleClickMenopausePuberty={(props:any) => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Menopause & Puberty"
          });
         navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
        }}
        handleClickEnhancedBenefits={() => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Enhanced Benefits"
          });
          props?.setShowEnhancBenefitModal(true);
        }}
        handelClickEnhancedBenefitsModal={(props:any)=>{
          navigate(`/claimSubmission/OPD=${props}?benefitsType=FLEXI-BENEFITS`); navigate(0);
        }}
        handleClickAnnualHealthCheckup={(props:any) => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: "Employee's Annual Health Checkup"
          });
         navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
        }}
        handleClickOtherBenefits={(props:any) => {
          dataLayerPush(Constants.Instance.CLAIM_SUBMISSION_CTA_INTERACTION, { 
            section_name: "Claim Submission",
            cta_text: props
          });
         navigate(`/claimSubmission/OPD=${props}?benefitsType=OPD`); navigate(0);
        }}
        // authenticateUser={authenticateUser}
        handleUserClick={handleUserClick}
        activeNumber={activeNumber}
        setActiveNumber={setActiveNumber}
      />}
    </div> 
  );
};

export default HeaderWeb;
