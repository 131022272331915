import React, { useEffect, useState } from 'react'
import HeaderWeb from './shared/Header'
import KycWebComponent from './KycWebComponent'
import { useLocation, useNavigate } from 'react-router'
import FooterWeb from './shared/Footer'
import { getSalesForceHealthBenefits } from '../reducers/salesForceHealthBenefits.slice'
import { getBaseProfile } from '../reducers/baseProfile.slice'
import { useAppSelector } from '../app/hooks'

const KYC = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [benefDetails, setbenefDetails] = useState(null as any)
  useEffect(() => {
    setbenefDetails(location?.state);
  }, [props])
    const baseProfile = useAppSelector(getBaseProfile)
    const salesForceBenefitsGetRespo = useAppSelector(getSalesForceHealthBenefits);
    const [showEnhancBenefitModal,setShowEnhancBenefitModal]= useState<boolean>(false);
  
    const closeEnhancBenefitModal = () => {
      setShowEnhancBenefitModal(!showEnhancBenefitModal);
    }
  
  return (
    <>
      <HeaderWeb isShowBackButton={true} oldHeader={true} name={""}
        claimSubmissionBenefits={salesForceBenefitsGetRespo?.benefitDetails}
        showEnhancBenefitModal={showEnhancBenefitModal}
        setShowEnhancBenefitModal={setShowEnhancBenefitModal}
        closeEnhancBenefitModal={closeEnhancBenefitModal}
        salesforceEntityId = {baseProfile?.data?.entityId}
      />
      {benefDetails &&
        <div style={{paddingTop: "75px"}}>
          <KycWebComponent maid={location?.state?.maid} benefName={location?.state?.benefName} />
        </div>
      }
      {/* <FooterWeb /> */}


    </>
  )
}

export default KYC

