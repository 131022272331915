import React, { useState } from 'react'
import {ResetPassword} from '@mediassistrepo/maven-old';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { getBaseProfile, isBaseProfileLoading } from '../reducers/baseProfile.slice';
import Loader from './shared/Loader';
import { tags } from '../constants/resetPassword';
import { changePasswordWithEmailSendOtp, changePasswordWithMobileSendOtp } from '../api/resetPasswordAPI';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';
import { getSalesForceHealthBenefits } from '../reducers/salesForceHealthBenefits.slice';


const ResetPasswordComponent = () => {
  const navigate = useNavigate();
  const [loader,setLoader] = useState(false);
  const profileData = useAppSelector(getBaseProfile);
  const isProfileDataLoading = useAppSelector(isBaseProfileLoading)
  const handleRedirect = (route:string,data?:any) => {
    navigate(route,{state: data});  
  }
  const salesForceBenefitsGetRespo = useAppSelector(getSalesForceHealthBenefits);
  const [showEnhancBenefitModal,setShowEnhancBenefitModal]= useState<boolean>(false);

  const closeEnhancBenefitModal = () => {
    setShowEnhancBenefitModal(!showEnhancBenefitModal);
  }
  
  const handleClick = async (eventType:string) => {
        setLoader(true);
        var response;
        let start = Date.now();
        let end, status;
        switch (eventType) {
            case tags.password:
                setLoader(false);
                dataLayerPush(Constants.Instance.RESET_PASSWORD_CTA_INTERACTION, { 
                  field_name: "Password"
                });
                handleRedirect(`/verify-otp/${(tags.password)}`,{
                  eventType: tags.password
              });
                // history.push(`/verify-otp/${encodeURIComponent(encrypt(tags.password))}`, {
                //     eventType: tags.password
                // });
                break;

            case tags.mobile:
                try {
                    response = await changePasswordWithMobileSendOtp();
                    dataLayerPush(Constants.Instance.RESET_PASSWORD_CTA_INTERACTION, { 
                      field_name: "Mobile"
                    });
                    if (response?.data?.isSuccess) {
                        end = Date.now();
                        status = true;
                        setLoader(false);
                        handleRedirect(`/verify-otp/${(tags.mobile)}`,{
                          eventType: tags.mobile,
                          eventValue: profileData.data?.mobileNo,
                          encryptedToken: response?.data?.encryptedToken,
                      })
                        // history.push(`/verify-otp/${encodeURIComponent(encrypt(tags.mobile))}`, {
                        //     eventType: tags.mobile,
                        //     eventValue: profileData?.mobileNo,
                        //     encryptedToken: response?.encryptedToken,
                        // });

                    }
                } catch (err) {
                    end = Date.now();
                    status = false;
                    setLoader(false);
                }
                // let timeTakenforMobile = end - start;
                // GaEvent("ProfileResetPassword_ChangePasswordMobileOTPClick", {
                //     category: "profileResetPassword",
                //     label: `${profileData.userName}`+'_'+`${profileData.maid}`+'_'+`${profileData.employeeId}`,
                //     userProperty:
                //         JSON.stringify({
                //             benefName:profileData.userName,
						    // maid:localStorage.getItem('maid'),
						    // employeeId:profileData.employeeId,
                //             OTPSentSuccess: status,
                //             timeTakenResponse: timeTakenforMobile + " ms"
                //         })
                // });

                break;

            case tags.email:
                try {
                    response = await changePasswordWithEmailSendOtp();
                    dataLayerPush(Constants.Instance.RESET_PASSWORD_CTA_INTERACTION, { 
                      field_name: "Email"
                    });
                    if (response?.data?.isSuccess) {
                        end = Date.now();
                        status = true;
                        setLoader(false);
                        handleRedirect(`/verify-otp/${(tags.email)}`,{
                          eventType: tags.email,
                          eventValue: profileData?.data?.emailId,
                          encryptedToken: response?.data?.encryptedToken
                      })
                        // history.push(`/verify-otp/${encodeURIComponent(encrypt(tags.email))}`, {
                        //     eventType: tags.email,
                        //     eventValue: profileData?.emailId,
                        //     encryptedToken: response?.encryptedToken
                        // });
                    }
                } catch (err) {
                    end = Date.now();
                    status = false;
                    setLoader(false);
                }
                // let timeTakenforEmail = end - start;
                // GaEvent("ProfileResetPassword_ChangePasswordEmailOTPClick", {
                //     category: "profileResetPassword",
                //     label: `${profileData.userName}`+'_'+`${profileData.maid}`+'_'+`${profileData.employeeId}`,
                //     userProperty:
                //         JSON.stringify({
                //             benefName:profileData.userName,
						    // maid:localStorage.getItem('maid'),
						    // employeeId:profileData.employeeId,
                //             OTPSentSuccess: status,
                //             timeTakenResponse: timeTakenforEmail + " ms"
                //         })
                // });
                break;

            default:
                break;
        }
    }
  
  if(isProfileDataLoading || loader) return <Loader/>;

  return (
    <div>
      <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Reset Password"} 
       claimSubmissionBenefits={salesForceBenefitsGetRespo?.benefitDetails}
       showEnhancBenefitModal={showEnhancBenefitModal}
       setShowEnhancBenefitModal={setShowEnhancBenefitModal}
       closeEnhancBenefitModal={closeEnhancBenefitModal}
       salesforceEntityId = {profileData?.data?.entityId}
      />
      <ResetPassword handleClick={handleClick} profileData={profileData?.data} />
      {/* <FooterWeb /> */}
    </div>
  )
}

export default ResetPasswordComponent