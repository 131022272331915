import { useState, useEffect } from 'react';

const useDeviceAndBrowser = () => {
  const [deviceType, setDeviceType] = useState<string | null>(null);
  const [browserType, setBrowserType] = useState<string | null>(null);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const vendor = navigator.vendor;

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceType('iOS');
    } else if (/Android/i.test(userAgent)) {
      setDeviceType('Android');
    } else {
      setDeviceType('Other');
    }

    if (/Chrome/i.test(userAgent) && /Safari/i.test(userAgent) && !/Edge/i.test(userAgent)) {
      setBrowserType('Chrome');
    } else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
      setBrowserType('Safari');
    } else if (/Firefox/i.test(userAgent)) {
      setBrowserType('Firefox');
    } else if (/Edge/i.test(userAgent)) {
      setBrowserType('Edge');
    } else if (/OPR/i.test(userAgent)) {
      setBrowserType('Opera');
    } else {
      setBrowserType('Other');
    }
  }, []);

  return { deviceType, browserType };
};

export default useDeviceAndBrowser;
