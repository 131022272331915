import React, { useEffect, useState } from "react";
import HeaderWeb from "./shared/Header";
import { useLocation, useNavigate } from "react-router";
import FooterWeb from "./shared/Footer";
import NewKycWebComponent from "./NewKycWebComponent";
import { Helmet } from "react-helmet-async";
import { useAppSelector } from "../app/hooks";
import { getBaseProfile } from "../reducers/baseProfile.slice";
import { getSalesForceHealthBenefits } from "../reducers/salesForceHealthBenefits.slice";

const NewKYC = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [benefDetails, setbenefDetails] = useState(null as any);
  useEffect(() => {
    setbenefDetails(location?.state);
  }, [props]);
  const baseProfile = useAppSelector(getBaseProfile)
  const salesForceBenefitsGetRespo = useAppSelector(getSalesForceHealthBenefits);
  const [showEnhancBenefitModal,setShowEnhancBenefitModal]= useState<boolean>(false);

  const closeEnhancBenefitModal = () => {
    setShowEnhancBenefitModal(!showEnhancBenefitModal);
  }

  return (
    <>
      <Helmet>
        <title>MAven - KYC</title>
        <meta name="description" content="" />
      </Helmet>
      <HeaderWeb isShowBackButton={true} oldHeader={true} name={""} 
        claimSubmissionBenefits={salesForceBenefitsGetRespo?.benefitDetails}
        showEnhancBenefitModal={showEnhancBenefitModal}
        setShowEnhancBenefitModal={setShowEnhancBenefitModal}
        closeEnhancBenefitModal={closeEnhancBenefitModal}
        salesforceEntityId = {baseProfile?.data?.entityId}
      />
      {benefDetails && (
        <div style={{ paddingTop: "75px" }}>
          <NewKycWebComponent
            maid={location?.state?.maid}
            benefName={location?.state?.benefName}
            notify ={props?.notify}
          />
        </div>
      )}
      {/* <FooterWeb /> */}
    </>
  );
};

export default NewKYC;
