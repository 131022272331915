import React, { useCallback, useEffect, useState,useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAccessToken, getDbType, getMaid, getXrefToken } from "../utils";
import Loader from "./shared/Loader";
import { dataLayerPush } from "../utils/gtm";
import { useAppSelector } from "../app/hooks";
import { getIntimationRequest } from "../reducers/intimationRequest.slice";
import { getPlatform, getPlatformFromCookie } from "../common/helpers";
import { getBaseProfile } from "../reducers/baseProfile.slice";
import { NpsIntimation } from '@mediassistrepo/homepage-component';
import { EntityValue, FeedbackValue } from "../common/Nps/nps.models";
import { FeedBack, SetNpsFeedBack } from "../api/homeAPI";

export interface Base64Decryption {
    claimID?: number;
    intimationID?: number;
}

function IntimationComponent() {
    const navigate = useNavigate();
    const intimationRequest = useAppSelector(getIntimationRequest);
    const baseProfile = useAppSelector(getBaseProfile);
    const [buttonClick, setButtonClick] = useState(null);
    const [loading, setLoading] = useState(true)
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('accessToken') || getAccessToken();
    const source = searchParams.get('source') || "Maven";
    const dbType =  searchParams.get('dbType') || getDbType();
    const maid = searchParams.get('maid') || parseInt(getMaid());
    const oop = searchParams.get("oop");
    const webComponentRef = useRef(null);

    const [allDetail, setAllDetail] = useState({
        selectedBenefiNameData: '',
        IntimationIDData: '',
    });
    const [showNpsIntimation, setShowNpsIntimation] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [intimationCount, setIntimationCount] = useState(0);

    const priMaId = `${baseProfile?.data?.maid}`;

    const initializeIntimation = () => {
        const eventQuerry = document.querySelector("intimation-web-component");
        eventQuerry?.addEventListener("GO_BACK", (e: any) => {
            if(localStorage.getItem('isLinkPolicy') == 'true'){
                localStorage.setItem('isLinkPolicy','false')
                navigate("/");
            }
            else{
            navigate(-1);
            }
        });
        eventQuerry?.addEventListener("GO_HOME", (e: any) => {
            navigate("/");
        });
    };

    useEffect(()=>{
        webComponentRef.current = document.querySelector("intimation-web-component");
        if(webComponentRef?.current){
            webComponentRef?.current?.addEventListener("gtm-events",(e: any)=>{
                e?.detail && dataLayerPush?.(e?.detail[0],e?.detail[1]);
            })
        }
    },[])

    useEffect(() => {
        if(accessToken && dbType && maid){
        const component: any = document.querySelector("intimation-web-component");
        if (component) {
            component.event = buttonClick;
            component.addEventListener('back', (e: any) => {
                if (e && e.detail && e.detail.intimationId) {
                    navigate('/',{state: {intimations: true}})
                }
            });
    }
    }
    }, [buttonClick]);

    useEffect(() => {
        if(accessToken && dbType && maid){
            const component: any = document.querySelector("intimation-web-component");
        if (component) {
                component.addEventListener('intimation-success', (e: any) => {
                if (e && e.detail && e.detail.IntimationID) {
                        navigate('/oop?intimationID=' + e.detail.IntimationID)
                    }else if(e && e.detail && e.detail.data && e.detail.data.intimationId){
                        navigate('/oop?intimationID=' + e.detail.data.intimationId)
                    }
                });
            }
        setLoading(false)
        initializeIntimation();
    }
    }, [accessToken && dbType && maid]);
    
    useEffect(() => {
        if(accessToken && dbType && maid){
            const component: any = document.querySelector("intimation-web-component");
        if (component) {
            component.addEventListener('feedback', (e: any) => {
                setAllDetail(e?.detail)
                fetchNpsIntimationApi(priMaId, 'NPS_INTIMATION');
            });
        }
    }
    }, [accessToken && dbType && maid]);
    
    const fetchNpsIntimationApi = async (priMaId: string, entityType: string) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        
        try {
            const feedback = await FeedBack(priMaId, entityType);
    
            if (!feedback?.data) {
                throw new Error("No data returned from the feedback API");
            }
    
            const remindLaterCount = feedback?.data?.feedback_value?.remind_later?.count || 0;
    
            if (feedback?.data?.statusCode === 204 || remindLaterCount < 3) {
                setShowNpsIntimation(true);
            } else {
                setShowNpsIntimation(false);
            }
        } catch (err) {
        } finally {
            setIsSubmitting(false);
        }
    };

    const remindLater = useCallback(async (
        type: string,
        setCount: React.Dispatch<React.SetStateAction<number>>,
        closePopup: () => void
    ) => {
        try {
            const response = await FeedBack(priMaId, type);
            let currentCount = response?.data?.feedback_value?.remind_later?.count || 0;

            const newCount = currentCount + 1;
            setCount(newCount);

            if (newCount > 3) {
                closePopup();
            }

            const feedbackValue: FeedbackValue = { remind_later: { count: newCount } };
            const entityValue: EntityValue = { feedback_value: feedbackValue, feedback_rating: 0 };

            const requestObject = {
                source: 'nps_intimation',
                entity_Type: type,
                entity_Id: priMaId.toString(),
                entity_Value: entityValue,
            };

            const updateResponse = await SetNpsFeedBack(requestObject);
            if (updateResponse?.data?.isSuccess) {
                closePopup();
            } else {
            }
        } catch (err) {
        }
    }, [priMaId]);

    const intimationRemindLater = () => {
        remindLater('NPS_INTIMATION', setIntimationCount, () => setShowNpsIntimation(false));
    };

    const feedback = useCallback(async (data: { sliderValue: number; checkedStates: string[]; remarks: string }) => {
        let feedbackValue: Record<string, any> = {
        };

        if (data?.sliderValue >= 8) {
            feedbackValue["Why did you like the experience?"] = data?.checkedStates;
        } else if (data?.sliderValue > 0 && data?.sliderValue < 8) {
            feedbackValue["What did you dislike about the experience?"] = data?.checkedStates;
        }

        feedbackValue['Beneficiary name'] = allDetail?.selectedBenefiNameData;
        feedbackValue['Primary beneficiary MA ID'] = priMaId;
        feedbackValue['Intimation ID'] = allDetail?.IntimationIDData;
        feedbackValue['Mobile'] = `${baseProfile?.data?.mobileNo}`;
        feedbackValue['Email ID'] = `${baseProfile?.data?.emailId}`;
        feedbackValue['Do you have any suggestions for improving the claim Intimation process?'] = data?.remarks;

        const entityValue: EntityValue = {
            feedback_rating: data?.sliderValue,
            feedback_value: feedbackValue
        };

        const requestObject = {
            source: 'nps_intimation',
            entity_Type: 'NPS_INTIMATION',
            entity_Id: priMaId.toString(),
            entity_Value: entityValue,
        };

        try {
            const response = await SetNpsFeedBack(requestObject);
            if (response?.data?.isSuccess) {
                // setShowNpsIntimation(false);
            } else {
            }
        } catch (err) {
        }
    }, [allDetail?.selectedBenefiNameData, priMaId, allDetail?.IntimationIDData, baseProfile]);

    const goBack = () => {
        setShowNpsIntimation(false);
    }

    return (
        <React.Fragment>
            {loading && <Loader/>}
            {accessToken && dbType && maid  &&  <div style={{ padding: "20px", paddingBottom: "0px"}}>
                <>
                    {showNpsIntimation && (
                        <NpsIntimation
                            benefName={allDetail?.selectedBenefiNameData}
                            intimationId={allDetail?.IntimationIDData}
                            priBenefId={priMaId}
                            source={'nps_intimation'}
                            entity_Type={'NPS_INTIMATION'}
                            remindLater={intimationRemindLater}
                            onSubmit={feedback}
                            goBack={goBack}
                        />
                    )}

                    <intimation-web-component
                    maid={maid}
                    dbType={dbType}
                    isOOP={oop}
                    token={accessToken}
                    request={JSON.stringify(intimationRequest)}
                    source={getPlatform()}
                    isAssumptionFlow={oop?.toLowerCase()==="true"}
                    ></intimation-web-component>
                 </>
            </div>}
        </React.Fragment>
    );
}

export default IntimationComponent;
