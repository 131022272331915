import React, { useEffect, useState } from 'react'
import { FamilyMemberComponent } from '@mediassistrepo/maven-old';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { configAsync, getConfig } from '../reducers/config.slice';
import { beneficiariesAsync, getBeneficiaries, getBeneficiariesLoading } from '../reducers/beneficiaries.slice';
import { baseProfileAsync, getBaseProfile } from '../reducers/baseProfile.slice';
import { getKycDetails, getKycDetailsLoading, kycDetailsAsync } from '../reducers/kycDetails.slice';
import { kycDetailsWithMAIDsReqPayload } from '../interfaces/familyMemberDetails.interface';
import { KycDetails, basePrfile, beneficiaryDetails, corporateConfigJSon } from '../constants/familyMemberDetails';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import Loader from './shared/Loader';
import { useNavigate } from 'react-router';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';
import { abhaIdsAsync, getAbhaIdsDetails, getAbhaIdsLoading } from '../reducers/abhaIds.slice';
import { getSalesForceHealthBenefits } from '../reducers/salesForceHealthBenefits.slice';
import { getcontact } from '../reducers/updateContact.slice';

const FamilyMemberDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const baseProfile = useAppSelector(getBaseProfile)
  const corporateConfig = useAppSelector(getConfig)
  const beneficiaries = useAppSelector(getBeneficiaries);
  const areBeneficiariesLoading = useAppSelector(getBeneficiariesLoading);
  const areKycDetailsLoading = useAppSelector(getKycDetailsLoading);
  const kycDetails = useAppSelector(getKycDetails);
  const areAbhaDetailsLoading = useAppSelector(getAbhaIdsLoading);
  const abhaDetails = useAppSelector(getAbhaIdsDetails);
  const salesForceBenefitsGetRespo = useAppSelector(getSalesForceHealthBenefits);
  const [showEnhancBenefitModal,setShowEnhancBenefitModal]= useState<boolean>(false);
  const contactInfo = useAppSelector(getcontact);

  const closeEnhancBenefitModal = () => {
    setShowEnhancBenefitModal(!showEnhancBenefitModal);
  }

  useEffect(() => {
    if (beneficiaries && beneficiaries.beneficiaryDetails && beneficiaries.beneficiaryDetails.length) {
      const maidsArray = beneficiaries.beneficiaryDetails.map((item) => item.mediAssistId);
      const kycDetailsPayload = new kycDetailsWithMAIDsReqPayload();
      kycDetailsPayload.maids = maidsArray;
      dispatch(kycDetailsAsync(kycDetailsPayload));
      dispatch(abhaIdsAsync(kycDetailsPayload));
    }
  }, [beneficiaries]);
  const handleEditContacts = async (data:any) => {
    dataLayerPush(Constants.Instance.EDIT_CONTACT_DETAIL_CTA_CLICK, { 
      section_name:"Family member details",
      cta_text:"Edit contact details"
    });
    if ((corporateConfig?.showUpdateContact   && (contactInfo?.data?.mobileNo.length >= 2 || contactInfo?.data?.emailId ?.length >= 2)) ) {
      navigate(`/updateContact`,{state:{familyData: data}});
    }else{
      navigate("/profile");
    }
  };

  const handleKycRedirect = (data:any) => {
    navigate("/new-kyc",{state: data});
  }
  const handleAbhaRedirect =(data:any)=>{
    navigate("/abha",{state: data});
  }

  if(areBeneficiariesLoading || areKycDetailsLoading || areAbhaDetailsLoading) return <Loader/>

  return (
    <div style={{ paddingBottom: "50px" }}>
      <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Family Members"}
        claimSubmissionBenefits={salesForceBenefitsGetRespo?.benefitDetails}
        showEnhancBenefitModal={showEnhancBenefitModal}
        setShowEnhancBenefitModal={setShowEnhancBenefitModal}
        closeEnhancBenefitModal={closeEnhancBenefitModal}
        salesforceEntityId = {baseProfile?.data?.entityId}
      />
      <FamilyMemberComponent
        base_profile={baseProfile?.data}
        beneficiaryDetails={beneficiaries?.beneficiaryDetails}
        corporateConfig={corporateConfig}
        kyc_Details={kycDetails?.kycDetails}
        abhaDetails={abhaDetails?.value}
        handleEditContacts={handleEditContacts}
        handleKycRedirect={handleKycRedirect}
        handleAbhaRedirect ={handleAbhaRedirect}
      />
      {/* <FamilyMemberComponent
        base_profile={basePrfile}
        beneficiaryDetails={beneficiaryDetails}
        corporateConfig={corporateConfigJSon}
        kyc_Details={KycDetails}
      /> */}
      {/* <FooterWeb /> */}
    </div>
  )
}

export default FamilyMemberDetails
