import React, { useState } from 'react'
import {
    ForgotPassword
  } from '@mediassistrepo/maven-old';
import { resetPasswordApi } from '../api/loginAPI';
import HeaderWeb from './shared/Header';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ResetPasswordRequest } from '../interfaces/login';
import { Helmet } from 'react-helmet-async';
import { useAppSelector } from '../app/hooks';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import { getSalesForceHealthBenefits } from '../reducers/salesForceHealthBenefits.slice';

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const baseProfile = useAppSelector(getBaseProfile);
  const salesForceBenefitsGetRespo = useAppSelector(getSalesForceHealthBenefits);
  const [showEnhancBenefitModal,setShowEnhancBenefitModal]= useState<boolean>(false);

  const closeEnhancBenefitModal = () => {
  setShowEnhancBenefitModal(!showEnhancBenefitModal);
  }
  // Parse the query string using URLSearchParams
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get('token');
  const key = queryParams.get('key');
  
  const handleResetPassword = async (data: ResetPasswordRequest) => {
        const res = await resetPasswordApi(data);
        return res?.data;
  }
    
  return (
    <div style={{paddingTop:"70px"}}>
      <Helmet>
        <title>MAven - Forgot password</title>
        <meta name="description" content="" />
      </Helmet>
    <HeaderWeb onBackBtnClick={() => navigate('/')}  isShowBackButton={true} oldHeader={true} name={"Go Back"}  
      claimSubmissionBenefits={salesForceBenefitsGetRespo?.benefitDetails}
      showEnhancBenefitModal={showEnhancBenefitModal}
      setShowEnhancBenefitModal={setShowEnhancBenefitModal}
      closeEnhancBenefitModal={closeEnhancBenefitModal}
      salesforceEntityId = {baseProfile?.data?.entityId}
    />
    <ForgotPassword resetPassword={handleResetPassword}/>
    </div>
  )
}

export default ForgotPasswordComponent