// @ts-ignore
import React, { useEffect, useRef, useState } from 'react'
// @ts-ignore
import {
  PolicyList
} from '@mediassistrepo/policy-list';
import "@mediassistrepo/policy-list/dist/style.css";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { beneficiariesAsync, getActiveBeneficiaries, getBeneficiaries, setActiveBeneficiaries } from '../reducers/beneficiaries.slice';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import { useNavigate } from 'react-router';
import { feedBackCommon, getCookieValue, postToNativeApp } from '../common/helpers';
import {Members} from '@mediassistrepo/homepage-component';
import {DownloadECard} from '@mediassistrepo/homepage-component';
import { formatDateToObject, getAccessToken } from '../utils';
import Loader from './shared/Loader';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import { downloadEcardByMember, downloadEcardFile } from '../utils/downloadEcard';
import { useSearchParams } from 'react-router-dom';
import SurveyMain from '../common/Survey/SurveyMain';
import { setappliedFilters, getappliedFilters } from '../reducers/policyList.slice';
import Constants from '../constants/Constants';
import { dataLayerPush } from '../utils/gtm';
import { Helmet } from 'react-helmet-async';
import { getSalesForceHealthBenefits } from '../reducers/salesForceHealthBenefits.slice';

export default function PolicyListComponent(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const beneficiariesResponse = useAppSelector(getBeneficiaries)
  const cachedActiveBeneficiaries  = useAppSelector(getActiveBeneficiaries);
  const [showPolicyCard, setShowPolicyCard] = React.useState<any>(false);
  const [policies, setPolicies] = React.useState<any>(null);
  const [toggleModal, setToggleModal] = React.useState<any>(false);
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const nativeApp = getCookieValue('source') === 'native' ? true : false;
  const accessToken = getAccessToken();
  const [loading, setLoading] = React.useState(false)
  const baseProfile = useAppSelector(getBaseProfile);
  const [feedBackModalShow,setFeedBackModalShow]=useState(false);
  const [maid,setMaid]=useState<string>('');
  const appliedFilters = useAppSelector(getappliedFilters);
  const [footerClick, setFooterClick] = useState(false);
  const [uniqueActiveBenefs, setUniqueActiveBenefs]  = useState(cachedActiveBeneficiaries);
  const webComponentRef = useRef(null);

  const salesForceBenefitsGetRespo = useAppSelector(getSalesForceHealthBenefits);
  const [showEnhancBenefitModal,setShowEnhancBenefitModal]= useState<boolean>(false);

  const closeEnhancBenefitModal = () => {
    setShowEnhancBenefitModal(!showEnhancBenefitModal);
  }
  

  useEffect(() => {
    const uniqueMaidMap = new Map<Number,Boolean>(); 
    const uniqueActiveBenefsTemp = new Array<any>();
    if(cachedActiveBeneficiaries?.length > 0){
      cachedActiveBeneficiaries.forEach((benef) => {
        if(!uniqueMaidMap.has(benef?.mediAssistId)){
         uniqueActiveBenefsTemp.push(benef);
         uniqueMaidMap.set(benef?.mediAssistId,true);
        }
     });
     setUniqueActiveBenefs(uniqueActiveBenefsTemp);
    }

  },[cachedActiveBeneficiaries]);



  useEffect(() => {
    if (!beneficiariesResponse) {

      dispatch(beneficiariesAsync());
    }
  }, []);
  useEffect(() => {
    setPolicies(beneficiariesResponse)
  }, [beneficiariesResponse]);

  useEffect(() => {
    if (getCookieValue('platform') == 'ios') {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    else if(getCookieValue('platform') == 'android'){
      
      document.addEventListener('message', handleMessage);
      return () => {
        document.removeEventListener('message', handleMessage);
      };
    }
  }, []);


  useEffect(() => {
    if(cachedActiveBeneficiaries){
       return;
    }
    const activeBeneficiaries = beneficiariesResponse?.beneficiaryDetails
      ?.filter((benefs) => formatDateToObject(benefs?.policyEndDate) >= new Date());

    postToNativeApp({"activeBenefCount":activeBeneficiaries?.length})
    
    dispatch(setActiveBeneficiaries(activeBeneficiaries));

  }, [beneficiariesResponse]);
  
  const handleMessage = (event: any) => {
    const message = JSON.parse(event.data);
    if (message.event === 'urlToWeb') {
      if (message.data.includes('claim-list')) {
        navigate("/claim-list")
      }
      else if (message.data.includes('policy-list')) {
        navigate("/policy-list")
      }
      else if (message.data.includes('account')) {
        navigate("/account")
      }
      else {
        navigate("/")
      }
    }
    else if (message.event === 'EcardSuccess') {
      HandlefeedBack("E_CARD");
    }
  };
  const HandlefeedBack= async (entityType :string)=>{
    const show= await feedBackCommon(entityType)
    setFeedBackModalShow(show);
    setMaid(localStorage.getItem("maid") )
  }

  const cardType = (polId: any, polDetails: any[]): string | undefined => {
    const policy = polDetails.find(detail => detail?.policyId === polId);
    if (policy) {
      return policy?.polFloater ? "Floater" : "Non-Floater";
    }
  }

  useEffect(()=>{
    if (!webComponentRef.current) {
    webComponentRef.current = document.querySelector("policy-list-web-component");
    if(webComponentRef.current){
      webComponentRef.current?.addEventListener("POLICY_CLICK", (e: any) => {
      const data = e?.detail;
      let section="";
            if(data.currentTab){section="Active policy"}
            else{section = "Inactive Policy"};
            dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CARD_INTERACTION, { 
              section_name:section,
              card_type : cardType(data?.data?.policyId,policies?.policyDetails) || "NA"
            });
      if (data && data.data && data.data.policyId)
        navigate("/policy?policyId=" + data.data.policyId)
    });
    webComponentRef.current?.addEventListener("FILTER_CLICK", (e: any) => {
        const filterClicked = e?.detail;
        if (filterClicked){
          dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CTA_INTERACTION, { 
            cta_text:"Filter"
          });
        }
        postToNativeApp({ "hideFooter": filterClicked })
    });
    webComponentRef.current?.addEventListener("GTM_EVENTS",(e: any)=>{
      e?.detail && dataLayerPush?.(e?.detail[0],e?.detail[1]);
    });
    webComponentRef.current?.addEventListener("SET_FILTERS",(e:any)=>{
      dispatch(setappliedFilters(e?.detail));
    })
  }
}
  });

  return (
    <>
      <Helmet>
        <title>MAven - Policy</title>
        <meta name="description" content="" />
      </Helmet>
      {(loading) && <Loader />}
      {feedBackModalShow && <div className="relative">
      <SurveyMain pageType="Policy List" pageTitle='E-card successfully downloaded.' Id={maid} source ={"ecard"} entity_Type={"E_CARD"} closeFeedbackModal={() => setFeedBackModalShow(!feedBackModalShow)}/>
      </div>}
      <HeaderWeb show={false}  handleEcard={() => props?.setIsEcardOpen(true)}
        showSubmitClaim={props?.showClaimSubmissionModal}
        handleClickHospital={() => {
        }}
        handleClickClinic={() => {navigate("/claimSubmission/OPD"); navigate(0)}}
        handleClickWellness={() => { navigate("")}}
        onClose={() => {
        props?.setShowClaimSubmissionModal(false)}}
        claimSubmissionBenefits={salesForceBenefitsGetRespo?.benefitDetails}
        showEnhancBenefitModal={showEnhancBenefitModal}
        setShowEnhancBenefitModal={setShowEnhancBenefitModal}
        closeEnhancBenefitModal={closeEnhancBenefitModal}
        salesforceEntityId = {baseProfile?.data?.entityId}
      />
      <DownloadECard 
       insideContainer={`calc(100dvh - 30dvh)`}
       styleOutSide={{height:"100vh"}}
      sectionName={footerClick? Constants.Instance.BOTTOM_NAVIGATION_BAR:Constants.Instance.TOP_NAVIGATION_BAR} 
      dataLayerPush={dataLayerPush}
       isVisible={props.isEcardOpen} toggleBottomModal={() => {
         props.setIsEcardOpen(false);
      }} membersData={uniqueActiveBenefs || []}
        handleEcardDownload={async (selectedMaids:any) => {
          await downloadEcardByMember((loading: boolean) => setLoading(loading), baseProfile,selectedMaids );  
            HandlefeedBack("E_CARD");
        }} onCloseClick={() => {
          if (getCookieValue('source') === 'native') {
            postToNativeApp({ "hideFooter": false })
            }
          props.setIsEcardOpen(false)
        }} ></DownloadECard>
      {/* <div className="px-5 mx-auto" style={{ paddingBottom: "90px" }}>
        {policies && !showPolicyCard && <PolicyList
          locale={'en'}
          beneficiariesResponse={policies} 
          onClickPolicyCard={(data: any) => {
            let section="";
            if(data.currentTab){section="Active policy"}
            else{section = "Inactive Policy"};
            dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CARD_INTERACTION, { 
              section_name:section,
              card_type : cardType(data.data.policyId,policies.policyDetails) || "NA"
            });
            if (data && data.data && data.data.policyId)
              navigate("/policy?policyId=" + data.data.policyId)
          }}
          appliedFilters = {appliedFilters}
          setAppliedFilters = {(value: any ) =>{
            dispatch(setappliedFilters(value))
          }}
          dataLayerPush = {dataLayerPush}
          onClickFilter={
            (filterClicked: Boolean) => {
              if (filterClicked){
                dataLayerPush(Constants.Instance.CLAIMS_POLICIES_CTA_INTERACTION, { 
                  cta_text:"Filter"
                });
              }
              postToNativeApp({ "hideFooter": filterClicked })
            }
          } />}
      </div> */}
      <div className="px-5 mx-auto" style={{ paddingBottom: "90px" }}>
      {policies && !showPolicyCard && 
      <policy-list-web-component
       locale={'en'}
       accessToken= {accessToken}
       appliedFilters={JSON.stringify(appliedFilters)}
      ></policy-list-web-component>
      }
      </div>
      {(!nativeApp || props.showClaimSubmissionModal) && localStorage.getItem('source') !==  Constants.Instance.MEDIBUDDY && <FooterWeb handleEcard={() => {props.setIsEcardOpen(true);setFooterClick(true)}} showSubmitClaim={props.showClaimSubmissionModal} onClose={()=>{         
         postToNativeApp({ "hideFooter": false })
         props.setShowClaimSubmissionModal(false)
       }}
       claimSubmissionBenefits={salesForceBenefitsGetRespo?.benefitDetails}
       showEnhancBenefitModal={showEnhancBenefitModal}
       setShowEnhancBenefitModal={setShowEnhancBenefitModal}
       closeEnhancBenefitModal={closeEnhancBenefitModal}
       salesforceEntityId = {baseProfile?.data?.entityId}
      />}
   
    </>
  )
}